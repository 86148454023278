import { createSlice } from '@reduxjs/toolkit';
import CsvData from './c.json'

// create slice
const name = 'coffees';
const initialState = createInitialState();
const reducers = createReducers(); 
const slice = createSlice({
    name,
    initialState,
    reducers,
});

// exports
export const coffeesActions = { ...slice.actions };
export const coffeesReducer = slice.reducer;

function createInitialState() {
    return { coffees: [] }
}

function createReducers() {
    return {
        getCoffees
    };
    function getCoffees(state,action) {
        
        state.coffees = CsvData
    };
};

