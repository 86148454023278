import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { setRoles } from "../_store";
import handImage from "../image/hand-t.jpg";

export { Home };

function Home() {
  
  useEffect(() => {
    const link = document.createElement("link");
    link.href = "https://fonts.googleapis.com/css2?family=Lobster&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const { users } = useSelector((x) => x.users);
  const _roles = useSelector((x) => x.roles.roles);
  useEffect(() => {
    if (authUser?.accessToken) {
      const decodedToken = jwtDecode(authUser?.accessToken);
      const userRoles = decodedToken?.roles?.map((e) => e.authority);
      dispatch(setRoles(userRoles));
    }
  }, []);

  const [activeItem, setActiveItem] = useState(0);
  function clickonnavigation(p) {
    setActiveItem(p);
    //fetch("http://20.85.153.120/api/student/all").then(e=>e.text()).then(e=>console.log('e',e))
  }
  return (
    
    <div>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1 style={{ fontSize: "36px", fontWeight: "bold", marginBottom: "20px" }}>
          Welcome to <span style={{ color: "black" , fontFamily: "Lobster, sans-serif"}}>Review</span>
          <span style={{ color: "#FFD700" , fontFamily: "Lobster, sans-serif"}}>Faster!</span>
        </h1>
        <p style={{ fontSize: "24px", color: "#555", fontStyle: "italic" }}>Fast Facts, Fluent Finds</p>
      </div>
      <img src={handImage} style={{ width: "70vw", display: "block", margin: "0 auto" }} alt="Hand Image" />
    </div>
  );

  return (
    <div>
      <div className="p-5">
        <h3>About page:</h3>
        <p class="font-weight-normal text-justify">
          Welcome to ReviewFaster.com the ultimate destination for informed
          decision-making in the ever-evolving world of products and services.
          As your go-to source, we take pride in offering a myriad of innovative
          features designed to provide comprehensive insights and analyses. From
          detailed product comparisons that empower you to tailor your choices
          to your needs through enhanced tools showcasing features,
          specifications, and user experiences, to in-depth technical reviews
          unravelling the intricacies of the latest products and technologies,
          we ensure you have the information needed before making a purchase.
          Our commitment extends to the power of data integration, guaranteeing
          that you receive the most up-to-date and relevant information for your
          decision-making process. Subscribe to personalized email
          recommendations keeping you informed about the latest trends, product
          releases, and exclusive deals, and effortlessly compare prices to
          ensure you get the best value for your investment. Navigate our
          platform with ease using filters dedicated to renewed and refurbished
          products, providing you with more choices within your budget.
          Visualize product features and user sentiments through our unique word
          cloud visualizations, gaining key insights at a glance. Recognizing
          the value of your time, our platform is designed to provide quick,
          time-saving insights, streamlining your decision- making process.
          Access detailed reports summarizing product comparisons, trends, and
          insights, empowering you with a wealth of information to make
          well-informed choices. At ReviewFaster.com, our dedication lies in
          simplifying your decision-making process and empowering both tech
          enthusiasts and casual shoppers with the knowledge needed for
          confident and informed choices. Welcome to a new era of informed
          decision-making!
        </p>
      </div>
      {users.length && (
        <ul>
          {users.map((user) => (
            <li key={user.id}>
              {user.firstName} {user.lastName}
            </li>
          ))}
        </ul>
      )}
      {users.loading && (
        <div className="spinner-border spinner-border-sm"></div>
      )}
      {users.error && (
        <div className="text-danger">
          Error loading users: {users.error.message}
        </div>
      )}
    </div>
  );
}
