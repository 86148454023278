import { useSelector, useDispatch } from "react-redux";
import { Product_tile } from "../product_tile/Product_tile";
import { useNavigate, useLocation } from "react-router-dom";
import { coffeesActions, teasActions } from "../_store";
import React, { useEffect, useState } from 'react';
import coffeImage from "../image/cf-bg.jpg";
import teaImage from "../image/te-bg.jpg";
import handImage from "../image/hand-t.jpg";
import commingImage from "../image/comming.jpg";
import travelImage from "../image/travel.jpg";
import "./style.css";

export { Products };

function Products() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [activeItem, setActiveItem] = useState(
    state?.activeItem ? state?.activeItem : localStorage.getItem('activeItem') || 0
  );
  const productsToShow = useSelector((state) => state.coffees.coffees);
  const teasToShow = useSelector((state) => state.teas.teas);
  
  useEffect(() => {
    localStorage.setItem('activeItem', activeItem);
  }, [activeItem]);
  
  function clickonnavigation(p) {
    setActiveItem(p);
    console.log("test");
    dispatch(coffeesActions.getCoffees());
    dispatch(teasActions.getTeas());
  }

  var filt = (products, filter_) => {
    return products.filter((e) => {
      if (Array.from(e.title)?.[0]?.toLowerCase() == filter_.toLowerCase())
        return e;
    });
  };
  return (
    <div>
      <div className="row fullscr">
        <div className="col-2 pr-0 ">
          <ul className="pl-1">
            <li
              onClick={() => clickonnavigation(1)}
              className={
                [1, 11, 12, 13].indexOf(activeItem) > -1
                  ? "active mt-2 h6"
                  : "mt-2 h6"
              }
            >
              Coffee
            </li>
            <div
              className={
                [1, 11, 12, 13].indexOf(activeItem) > -1
                  ? "subcategory"
                  : "d-none subcategory"
              }
            >
              <ol className="pl-3">
                <li
                  onClick={() => clickonnavigation(11)}
                  className={activeItem === 11 ? "sactive" : ""}
                >
                  Lavazza
                </li>
                <li
                  onClick={() => clickonnavigation(12)}
                  className={activeItem === 12 ? "sactive" : ""}
                >
                  Starbucks
                </li>
                <li
                  onClick={() => clickonnavigation(13)}
                  className={activeItem === 13 ? "sactive" : ""}
                >
                  Coming soon ...
                </li>
              </ol>
            </div>
            <li
              onClick={() => clickonnavigation(2)}
              className={activeItem === 2 ? "active mt-2 h6" : "mt-2 h6"}
            >
              Tea
            </li>
            <div
              className={
                [2, 21, 22].indexOf(activeItem) > -1
                  ? "subcategory"
                  : "d-none subcategory"
              }
            >
              <ol className="pl-3">
                <li
                  onClick={() => clickonnavigation(21)}
                  className={activeItem === 21 ? "sactive" : ""}
                >
                  Twinings
                </li>
                <li
                  onClick={() => clickonnavigation(22)}
                  className={activeItem === 22 ? "sactive" : ""}
                >
                  Coming soon ...
                </li>
              </ol>
            </div>
            <li
              onClick={() => clickonnavigation(3)}
              className={activeItem === 3 ? "active mt-2 h6" : "mt-2 h6"}
            >
              Other Products
            </li>
            <div
              className={
                [3, 31].indexOf(activeItem) > -1
                  ? "subcategory"
                  : "d-none subcategory"
              }
            >
              <ol className="pl-3">
                <li
                  onClick={() => clickonnavigation(31)}
                  className={activeItem === 31 ? "sactive" : ""}
                >
                  Coming soon ...
                </li>
              </ol>
            </div>
          </ul>
        </div>
        <div className="col-10 p-0">
          {activeItem === 0 && (
            <div className="coffee-home ">
              <img src={handImage} />
            </div>
          )}
          {activeItem === 1 && (
            <div className="coffee-home">
              <img src={coffeImage} />
            </div>
          )}
          {activeItem === 11 && (
            <div className="row m-0">
              {filt(productsToShow, "l")?.map((p, index) => (
                <Product_tile
                  className="col-md-2 mb-4"
                  key={index}
                  title={p.title}
                  detail={p.detail}
                  Link_AF={p.Link_AF}
                  imageUrl={p.imageUrl}
                  activeItem={activeItem}
                ></Product_tile>
              ))}
            </div>
          )}
          {activeItem === 12 && (
            <div className="row m-0">
              {filt(productsToShow, "s")?.map((p, index) => (
                <Product_tile
                  className="col-md-2 mb-4"
                  key={index}
                  title={p.title}
                  detail={p.detail}
                  Link_AF={p.Link_AF}
                  imageUrl={p.imageUrl}
                  activeItem={activeItem}
                ></Product_tile>
              ))}
            </div>
          )}
          {activeItem === 13 && (
            <div className="coffee-home ">
              <img src={commingImage} />
            </div>
          )}
          {activeItem === 2 && (
            <div className="coffee-home">
              <img src={teaImage} />
            </div>
          )}
          {activeItem === 21 && (
            <div className="row m-0">
              {teasToShow?.map((p, index) => (
                <Product_tile
                  className="col-md-2 mb-4"
                  key={index}
                  title={p.title}
                  detail={p.detail}
                  Link_AF={p.Link_AF}
                  imageUrl={p.imageUrl}
                  activeItem={activeItem}
                ></Product_tile>
              ))}
            </div>
          )}
          {activeItem === 22 && (
            <div className="coffee-home ">
              <img src={commingImage} />
            </div>
          )}
          {activeItem === 3 && (
            <div className="coffee-home ">
              <img src={travelImage} />
            </div>
          )}
          {activeItem === 31 && (
            <div className="coffee-home ">
              <img src={commingImage} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const cardw = {
  "max-width": "25%",
};
