import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import homeIcon from '../rvLogo.png';
import { authActions } from "../_store";

export { Nav };

function Nav(props) {
  const authUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const logout = () => dispatch(authActions.logout());
  const _roles = useSelector((x) => x.roles.roles);

  useEffect(() => {
    const link = document.createElement("link");
    link.href = "https://fonts.googleapis.com/css2?family=Lobster&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const nopadding = {
    paddingTop: 0,
    paddingBottom: 0,
  };

  // only show nav when logged in
  if (!authUser) {
    return (
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <div className="navbar-nav d-flex w-100 justify-content-between align-items-center">
          <div className="mr-auto d-flex">
            <NavLink to="/" className="nav-item nav-link mr-auto">
              <h1 style={{ fontSize: "36px", fontWeight: "bold", marginBottom: "20px" , fontFamily: "Lobster, sans-serif" }}>
              <span style={{ color: "black"}}>Review</span>
              <span style={{ color: "#FFD700"}}>Faster</span>
              </h1>
            </NavLink>
            <NavLink to="/products" className="nav-item nav-link mr-auto">
            <h1 style={{ fontSize: "36px", fontWeight: "bold", marginBottom: "20px" , color: "black" }}>
              Products
              </h1>
            </NavLink>
          </div>
          <div>
            <NavLink to="/login" className="nav-item nav-link mr-auto">
              Login
            </NavLink>
          </div>
        </div>
      </nav>
    );
  }

  return (
    <nav className="navbar navbar-expand navbar-dark bg-dark">
      <div className="navbar-nav d-flex w-100">
        <div className="mr-auto d-flex">
          <NavLink to="/" className="nav-item nav-link mr-auto">
            Home
          </NavLink>
          <NavLink to="/students" className="nav-item nav-link mr-auto">
            Students
          </NavLink>
          <NavLink to="/products" className="nav-item nav-link mr-auto">
              Products
            </NavLink>
        </div>
        <div>
          <button onClick={logout} className="btn btn-link nav-item nav-link">
            Logout
          </button>
          <div className="nav-item nav-link" style={nopadding}>
            {_roles?.toString()?.replaceAll("ROLE_", "")?.toLowerCase()}
          </div>
        </div>
      </div>
    </nav>
  );
}
