import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function Footer(props) {
  const [activeItem, setActiveItem] = useState(0);
  const [showAboutText, setShowAboutText] = useState(false);
  const [showContactText, setShowContactText] = useState(false);

  function clickonnavigation(p) {
    setActiveItem(p);
    setShowAboutText(p === 1 ? !showAboutText : false); // Toggle About text visibility
    setShowContactText(p === 2 ? !showContactText : false); // Toggle Contact Us text visibility
    //fetch("http://20.85.153.120/api/student/all").then(e=>e.text()).then(e=>console.log('e',e))
  }

  return (
    <footer className="footer text-center">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="footer-section">
              <ul className="pl-0"> {/* Removed default list styling */}
                <ul
                  onClick={() => clickonnavigation(1)}
                  className={
                    [1, 11, 12, 13].indexOf(activeItem) > -1
                      ? "active mt-2 h6"
                      : "mt-2 h6"
                  }
                >
                  <h5 style={{ cursor: "pointer" }}>About</h5>
                  {showAboutText && (
                    <p style={{ color: "white" }}>Welcome to ReviewFaster.com the ultimate destination for
                informed decision-making in the ever-evolving world of products
                and services. As your go-to source, we take pride in offering a
                myriad of innovative features designed to provide comprehensive
                insights and analyses. From detailed product comparisons that
                empower you to tailor your choices to your needs through
                enhanced tools showcasing features, specifications, and user
                experiences, to in-depth technical reviews unravelling the
                intricacies of the latest products and technologies, we ensure
                you have the information needed before making a purchase. Our
                commitment extends to the power of data integration,
                guaranteeing that you receive the most up-to-date and relevant
                information for your decision-making process. Subscribe to
                personalized email recommendations keeping you informed about
                the latest trends, product releases, and exclusive deals, and
                effortlessly compare prices to ensure you get the best value for
                your investment. Navigate our platform with ease using filters
                dedicated to renewed and refurbished products, providing you
                with more choices within your budget. Visualize product features
                and user sentiments through our unique word cloud
                visualizations, gaining key insights at a glance. Recognizing
                the value of your time, our platform is designed to provide
                quick, time-saving insights, streamlining your decision- making
                process. Access detailed reports summarizing product
                comparisons, trends, and insights, empowering you with a wealth
                of information to make well-informed choices. At
                ReviewFaster.com, our dedication lies in simplifying your
                decision-making process and empowering both tech enthusiasts and
                casual shoppers with the knowledge needed for confident and
                informed choices. Welcome to a new era of informed
                decision-making!
                As an Amazon Associate, we earn from qualifying purchases. This
                means that when you click on a link on this site and buy
                something from Amazon, we get a small commission at no extra
                cost to you. This helps us to maintain this website and provide
                you with valuable content. We appreciate your support and trust
                in our recommendations. Thank you for visiting our website and
                we hope you enjoy it!</p>
                  )}
                </ul>
                <ul
                  onClick={() => clickonnavigation(2)}
                  className={activeItem === 2 ? "active mt-2 h6" : "mt-2 h6"}
                >
                  <h5 style={{ cursor: "pointer" }}>Contact Us</h5>
                  {showContactText && (
                    <p style={{ color: "white" }}>Coming Soon...</p>
                  )}
                </ul>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <div className="footer-section">
              <p>© COPYRIGHT 2024 BY REVIEW FASTER ALL RIGHTS RESERVED</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export { Footer };
