import "./style.css";
import { useEffect, useState } from "react";
import { useNavigate ,useLocation  } from "react-router-dom";
export { Product_detail };

function Product_detail() {
  const location = useLocation();
  const navigate = useNavigate();
  // Accessing the passed data from NewComponent
  const { state } = location;
  const props = state;
  function navigateTo(){
    const data = {
      title: props.title,
      detail: props.detail,
      activeItem: props.activeItem
    };
    navigate('/products', { state: data });   
  }
  return (
    <div className="">
      <div className="d-flex justify-content-center pt-3 " >
        <a href={props.Link_AF} target="_blank">     
        <img
          src={props.imageUrl}
          className="imagge"
          alt="..."
        />
        </a>
      </div>
      <div className="card-details mt-5">
        <h5 className="h3 mb-5 font-weight-normal text-justify">{props.title}</h5>
        <div className="h5 card-details2 font-weight-normal text-justify">{props.detail}</div>
        <div className="d-flex justify-content-between pt-5">
          <button type="button" className="btn btn-left btn-sm mr-1">
            <a href={props.Link_AF} target="_blank"><div className="d-flex justify-content-center"><img className="amazon-logo mr-1" src="images\a34.png"></img>Buy on Amazon</div></a>
          </button>
          <button type="button" className="btn btn-warning" onClick={navigateTo}>
            Back to Collection
          </button>
        </div>
      </div>
    </div>
  );
}
