import { createSlice } from '@reduxjs/toolkit';
import CsvData from './t.json'

// create slice
const name = 'teas';
const initialState = createInitialState();
const reducers = createReducers(); 
const slice = createSlice({
    name,
    initialState,
    reducers,
});

// exports
export const teasActions = { ...slice.actions };
export const teasReducer = slice.reducer;

function createInitialState() {
    return { teas: [] }
}

function createReducers() {
    return {
        getTeas
    };
    function getTeas(state,action) {
        
        state.teas = CsvData
    };
};

