import "./style.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export { Product_tile };

function Product_tile(props) {
  const navigate = useNavigate();
  const [pdetail, setPdetail] = useState(
    props.detail.length > 102
      ? props.detail.substring(0, 70) + "..."
      : props.detail
  );

  function navigateTo() {
    const data = {
      title: props.title,
      detail: props.detail,
      Link_AF: props.Link_AF,
      imageUrl: props.imageUrl,
      activeItem: props.activeItem,
    };
    navigate("/pdp", { state: data });
  }

  useEffect(() => {
    console.log("data", props);
  }, []);

  return (
    <div className="card m-4">
      <a href={props.Link_AF} target="_blank">
        <div className="d-flex justify-content-center align-items-center pt-2 mh-custom">
          <img src={props.imageUrl} className="card-img-top" alt="..." />
        </div>
      </a>
      <div className="card-body p-3">
        <div className="card-title-text">
          <h5 className="text-center">{props.title}</h5>
          <p>{pdetail}</p>
        </div>
      </div>
      <div className="card-footer d-flex justify-content-between">
        <button type="button" className="btn btn-left btn-sm" onClick={() => window.open(props.Link_AF, "_blank")}>
          <div className="d-flex align-items-center">
            <img className="amazon-logo mr-1" src="images\a34.png" alt="Amazon Logo" />
            Buy on Amazon
          </div>
        </button>
        <button type="button" className="btn btn-right btn-sm" onClick={navigateTo}>
          Details
        </button>
      </div>
    </div>
  );
}